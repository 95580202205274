<template>
  <section class="service_topbar_section">
    <div class="service_container">
      <div class="top-bar-content">
        <div class="top-bar-log">
          <div class="go-logo-box">
            <!-- <feather-icon
              class="menu"
              icon="MenuIcon"
              size="20"
            /> -->
            <h1
              class="main-logo"
              @click="gotoHome"
            >
              <img
                :src="Logo"
                class=""
                alt="logo"
              >

            </h1>
            <div
              class="connection__light"
              :class="!connection ? 'is_connection_failed' : ''"
            />
            <!-- <span class="gt-s">GT-S</span> -->
          </div>

          <div
            v-if="userData && $route.name === 'service' && search_result === 'Search Results'"
            class="searc_filter_view"
          >

            <div class="filter_form_view">
              <div class="find_box">
                <span>State: </span>
                <input
                  v-model="state"
                  placeholder="Select state"
                  auto-complete="false"
                  @focus="showStateMenu"
                  @focusout="hideStateMenu"
                >
                <div
                  v-if="is_state_show"
                  class="menu_dropdown"
                >
                  <div
                    v-for="item in states"
                    :key="item.id"
                    class="menu-item"
                    @click="selectStateItem(item)"
                  >
                    <span> {{ item.name }} </span>
                  </div>
                </div>
              </div>
              <!-- <div class="line" /> -->
              <div class="find_box">
                <span>County:</span>
                <input
                  v-model="county"
                  placeholder="Select county"
                  auto-complete="false"
                  @focus="showCountyMenu"
                  @focusout="hideCountyMenu"
                >
                <div
                  v-if="is_county_show"
                  class="menu_dropdown categories_menu_scroll"
                >
                  <div
                    v-for="item in counties"
                    :key="item.id"
                    class="menu-item"
                    @click="selectCountyItem(item)"
                  >
                    <span> {{ item.name }} </span>
                  </div>
                </div>
              </div>
              <!-- <div class="line" /> -->
              <div class="find_box last_view">
                <span>Category:</span>
                <input
                  v-model="category"
                  placeholder="Select category"
                  auto-complete="false"
                  @focus="showCategoryMenu"
                  @focusout="hideCategoryMenu"
                >

                <div
                  v-if="is_category_show"
                  class="menu_dropdown"
                >
                  <div
                    v-for="item in categories"
                    :key="item.category_id"
                    class="menu-item"
                    @click="selectCategoryItem(item)"
                  >
                    <span> {{ item.name }} </span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div
          v-if="userData"
          class="profile_box"
        >
          <div
            v-if="user_type === 'member' || userData.is_vendor === true"
            class="top_menu_buttons"
          >
            <div
              v-b-tooltip.hover.top="'Search'"
              class="menu_item_view_button"
              @click="showSearchInput"
            >
              <img
                :src="SearchIcon"
                alt="icon"
              >
            </div>
            <div
              v-b-tooltip.hover.top="'Favorites'"
              class="menu_item_view_button"
            >
              <img
                :src="FavoriteIcon"
                alt="icon"
                @click="showFavorite"
              >
            </div>
            <!-- <div
              v-b-tooltip.hover.top="'Contacts'"
              class="menu_item_view_button"
              @click="$router.push({ name: 'contacts' }).catch(() => {})"
            >
              <img
                :src="ContactIcon"
                alt="icon"
              >
            </div>

            <div
              v-b-tooltip.hover.top="'Reviews'"
              class="menu_item_view_button"
              @click="$router.push({ name: 'service-reviews' }).catch(() => {})"
            >
              <img
                :src="ReviewIcon"
                alt="icon"
              >
            </div>
            <div
              v-b-tooltip.hover.top="'Recommendation History'"
              class="menu_item_view_button"
              @click="loadRecommendation"
            >
              <img
                :src="RecommendIcon"
                alt="icon"
              >
            </div> -->
          </div>
          <div
            class="go-profile-box"
          >
            <div class="menu-dropdown-profile-view">
              <span>
                <span>{{ user.first_name }} {{ user.last_name }}</span><br>
                <b-badge variant="light-success">
                  {{ user_type === 'member' ? user.profession : user_type }}
                </b-badge>

              </span>
              <!-- <feather-icon icon="ChevronDownIcon" /> -->
            </div>
            <div>
              <b-avatar
                v-if="user.avatar === null || user.avatar === ''"
                size="45"
                :text="getUserText(user.first_name, user.last_name)"
                variant="primary"
                badge
                class="badge-minimal"
                badge-variant="success"
              />
              <b-avatar
                v-else
                size="45"
                variant="light-primary"
                badge
                :src="getImage(userData.avatar)"
                class="badge-minimal"
                badge-variant="success"
              />
            </div>

            <div
              class="menu-dropdown"
            >
              <div class="menu_item_view">

                <div
                  v-if="user_type !== 'member'"
                  class="menu_item"
                  @click="switchToDashboard"
                >
                  <span>Dashboard</span>
                </div>
                <div
                  v-if="user_type === 'member' && user.is_vendor === true"
                  class="menu_item"
                  @click="$router.push({name: 'vendor-businesses'}).catch(() => {})"
                >
                  <span>My Business</span>
                </div>
                <div
                  v-if="user_type === 'member'"
                  class="menu_item"
                  @click="$router.push({ name: 'contacts' }).catch(() => {})"
                >
                  <span>My Contacts</span>
                </div>
                <div
                  v-if="user_type === 'member'"
                  class="menu_item"
                  @click="$router.push({ name: 'service-reviews' }).catch(() => {})"
                >
                  <span>My Reviews</span>
                </div>
                <div
                  v-if="user_type === 'member'"
                  class="menu_item"
                  @click="loadRecommendation"
                >
                  <span>My Recommendation</span>
                </div>

                <div
                  v-if="user_type === 'member'"
                  class="menu_item"
                  @click="$router.push({name: 'profile-settings'}).catch(() => {})"
                >
                  <span>My Profile</span>
                </div>

                <div
                  v-else
                  class="menu_item"
                  @click="$router.push({name: 'profile'}).catch(() => {})"
                >
                  <span>My Profile</span>
                </div>
                <div
                  class="menu_item"
                  @click="logout"
                >
                  <span>Logout</span>
                </div>

              </div>

            </div>
          </div>
        </div>
        <div v-else>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-success"
            @click="$router.push('/login').catch(() => {})"
          >
            Login
          </b-button>
        </div>
      </div>

    </div>

    <b-modal
      id="filter-modal"
      title="Search"
      hide-footer
    >
      <b-card-text>
        <b-form-group
          label="Select State"
          label-for="h-search-state"
          label-cols-md="12"
        >
          <b-form-select
            id="h-search-state"
            v-model="selected_state"
            :options="state_options"
            style="width: 100%"
            @change="changeStateSearch"
          />
        </b-form-group>

        <b-form-group
          label="Select County"
          label-for="h-search-state"
          label-cols-md="12"
        >
          <b-form-select
            id="h-search-state"
            v-model="selected_county"
            :options="county_options"
            style="width: 100%"
            @change="changeCountySearch"
          />
        </b-form-group>

        <b-form-group
          label="Select Category"
          label-for="h-search-state"
          label-cols-md="12"
        >
          <b-form-select
            id="h-search-state"
            v-model="selected_category"
            :options="categories_options"
            style="width: 100%"
            @change="changeCategorySearch"
          />
        </b-form-group>
      </b-card-text>
    </b-modal>
    <v-idle
      :loop="false"
      :reminders="[10]"
      :wait="5"
      :duration="600"
      style="visibility: hidden;"
      @idle="onidle"
      @remind="onremind"
    />
  </section>
</template>

<script>
import {
  BAvatar, VBTooltip, BButton, BModal, VBModal, BFormGroup, BCardText, BFormSelect, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Logo from '../../../assets/images/logo/logo.png'
import FavoriteIcon from '../../../assets/images/icons/heart-deep.png'
import ContactIcon from '../../../assets/images/icons/contact.png'
import RecommendIcon from '../../../assets/images/icons/recommendation.png'
import SearchIcon from '../../../assets/images/icons/search.png'
import ReviewIcon from '../../../assets/images/icons/star.png'

export default {
  components: {
    BAvatar,
    BModal,
    BCardText,
    BFormGroup,
    BFormSelect,
    BBadge,
    BButton,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  data() {
    return {
      FavoriteIcon,
      ContactIcon,
      RecommendIcon,
      ReviewIcon,
      selected_state: null,
      selected_county: null,
      selected_category: null,
      SearchIcon,
      is_menu_show: false,
      is_category_show: false,
      is_state_show: false,
      is_county_show: false,
      state: '',
      county: '',
      category: '',
      user_type: '',
      Logo,
      user: {
        first_name: '',
        last_name: '',
        user_type: '',
        avatar: '',
      },
      last_search_data: {
        state_name: '',
        state_id: null,
        county_name: '',
        county_id: '',
        category_name: '',
        category_id: '',
      },
    }
  },
  computed: {
    userData() {
      return this.$store.state.user.profile
    },
    states() {
      return this.$store.state.state.states
    },
    counties() {
      return this.$store.state.county.counties
    },
    state_options() {
      return this.states.map(item => {
        const data = { value: item.id, text: `${item.name}` }
        return data
      })
    },
    county_options() {
      return this.counties.map(item => {
        const data = { value: item.id, text: item.name }
        return data
      })
    },
    enable_sort() {
      return this.$store.state.businessProfile.enable_sort
    },
    categories() {
      return this.$store.state.category.categories_profile
      // const items = this.$store.state.category.categories_profile
      // return items.filter(item => item.name.toLowerCase().includes(this.category.toLowerCase()))
    },
    categories_options() {
      return this.categories.map(item => {
        const data = { value: item.category_id, text: item.name }
        return data
      })
    },
    profile_param: {
      get() {
        return this.$store.state.businessProfile.param
      },
      set(value) {
        return value
      },
    },
    activeData() {
      return this.$store.state.businessProfile.active_data
    },
    center: {
      get() {
        return this.$store.state.businessProfile.center
      },
      set(value) {
        return value
      },
    },
    search_result: {
      get() {
        return this.$store.state.businessProfile.search_result_text
      },
    },
    search_message: {
      get() {
        return this.$store.state.businessProfile.search_message
      },
      set(value) {
        return value
      },
    },
    last_search() {
      return this.$store.state.businessProfile.active_data
    },
    connection() {
      return this.$store.state.app.connection
    },
  },
  watch: {
    '$store.state.businessProfile.param': {
      handler(old, newData) {
        if (newData.states.length > 0 && newData.counties.length > 0 && newData.categories.length > 0 && this.enable_sort === false) {
          this.getBusinessProfile()
          this.$store.dispatch('recommend/updateRecommendationData', false)
          this.$bvModal.hide('filter-modal')
          // if(this.route.name)
          this.$store.dispatch('businessProfile/updateMessage', '')
          this.$store.dispatch('recommend/updateRecommendationData', false)
        } else if (newData.states.length === 0) {
          this.$store.dispatch('businessProfile/enableSort', false)
          this.$store.dispatch('recommend/updateRecommendationData', false)
          this.$store.dispatch('businessProfile/updateMessage', 'Please select state')
        } else if (newData.counties.length === 0) {
          this.$store.dispatch('businessProfile/enableSort', false)
          this.$store.dispatch('recommend/updateRecommendationData', false)
          this.$store.dispatch('businessProfile/updateMessage', 'Please select county')
        } else if (newData.categories.length === 0) {
          this.$store.dispatch('recommend/updateRecommendationData', false)
          this.$store.dispatch('businessProfile/enableSort', false)
          this.$store.dispatch('businessProfile/updateMessage', 'Please select category')
        }
      },
      deep: true,
    },
    last_search_data: {
      handler(old, newData) {
        // console.log('last_search', newData)
        this.$store.dispatch('businessProfile/getLastSearch', newData)
      },
      deep: true,
    },
    '$store.state.user.profile': {
      handler() {
        this.user = this.userData
      },
      deep: true,
    },
    '$store.state.category.categories_profile': {
      handler(oldValue, newValue) {
        if (newValue.length === 0) return
        if (oldValue.length === 0) {
          this.category = ''
          this.profile_param.categories = []
          this.selected_category = null
          this.$store.dispatch('businessProfile/EmptyProfileList')
        }
      },
      deep: true,
    },
    '$store.state.county.all_counties': {
      handler(oldValue, newValue) {
        if (oldValue.length > 0) {
          const data = JSON.parse(localStorage.getItem('gts_last_search'))
          if (data?.state_name?.length > 0) {
            this.$store.dispatch('county/getCounties', data.state_id)
            if (data?.county_name?.length > 0) {
              this.getCategory({ state_id: data.state_id, county_id: data.county_id })
              // this.last_search_data.category_name = data.category_name
              // this.last_search_data.category_id = data.category_id
            }
          }
        }
      },
    },
  },
  mounted() {
    this.loadUser()
    this.getState()
    this.getAllCounties()
    // this.getCategory()
    // this.getAllSubCategories()
    this.user = this.userData
    this.updateLastSearch()
    this.user_type = localStorage.getItem('gts_user_type')
    // this.state = this.user.state
    // this.county = this.user.county
  },
  methods: {
    loadUser() {
      this.$store.dispatch('user/setProfile')
    },
    updateLastSearch() {
      const data = JSON.parse(localStorage.getItem('gts_last_search'))
      if (data?.state_name?.length > 0 && data?.county_name?.length > 0 && data?.category_name?.length > 0
        && data?.state_id?.length !== null && data?.county_id?.length !== null && data?.category_id?.length !== null
      ) {
        this.profile_param.states = [data.state_id]
        this.profile_param.counties = [data.county_id]
        this.profile_param.categories = [data.category_id]
        this.last_search_data.state_name = data.state_name
        this.last_search_data.state_id = data.state_id
        this.last_search_data.county_name = data.county_name
        this.last_search_data.county_id = data.county_id
        this.last_search_data.category_name = data.category_name
        this.last_search_data.category_id = data.category_id
        this.state = data.state_name
        this.county = data.county_name
        this.category = data.category_name
        this.selected_state = data.state_id
        this.selected_county = data.county_id
        this.selected_category = data.category_id
        this.$store.dispatch('businessProfile/getLastSearch', data)
      } else if (data?.state_name?.length > 0) {
        this.last_search_data.state_name = data.state_name
        this.last_search_data.state_id = data.state_id
        this.profile_param.states = [data.state_id]
        this.state = data.state_name
        this.selected_state = data.state_id
      } else if (data?.state_county?.length > 0) {
        this.last_search_data.county_name = data.county_name
        this.last_search_data.county_id = data.county_id
        this.profile_param.county = [data.county_id]
        this.county = data.county_name
        this.selected_county = data.county_id
      }
    },
    getState() {
      return this.$store.dispatch('state/getStates')
    },
    onidle() {
      this.logout()
    },
    onremind(time) {
      // alert seconds remaining to 00:00
      this.idleWarning(time)
    },
    idleWarning(time) {
      this.$swal({
        title: 'Warning!',
        text: `You have left this app idle for 10mins, left with ${time} seconds to logout the app.`,
        icon: 'warning',
        timer: 6000,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    showFavorite() {
      this.$router.push({ name: 'favorites' }).catch(() => {})
    },
    loadRecommendation() {
      this.$router.push({ name: 'service-recommendations' }).catch(() => {})
    },
    gotoHome() {
      if (this.userData) {
        this.$router.push({ name: 'service' }).catch(() => {})
        this.updateLastSearch()
      } else {
        this.$router.push({ name: 'home' }).catch(() => {})
      }
    },
    showSearchInput() {
      const windowWidth = window.innerWidth
      if (windowWidth > 1679) {
        this.$store.dispatch('businessProfile/EmptyProfileList')
        this.$store.dispatch('recommend/updateRecommendationData', false)
        this.$store.dispatch('businessProfile/updateSearchResult', 'Search Results')
        this.gotoHome()
        if (this.profile_param.states.length < 1) {
          return this.$store.dispatch('businessProfile/updateMessage', 'Please select state')
        }
        if (this.profile_param.counties < 1) {
          return this.$store.dispatch('businessProfile/updateMessage', 'Please select county')
        }
        if (this.profile_param.categories < 1) {
          return this.$store.dispatch('businessProfile/updateMessage', 'Please select category')
        }
      } else {
        this.$router.push({ name: 'service' }).catch(() => {})
        this.$store.dispatch('recommend/updateRecommendationData', false)
        this.$store.dispatch('businessProfile/updateSearchResult', 'Search Results')
        this.$bvModal.show('filter-modal')
        if (this.selected_state !== null && this.selected_county !== null && this.selected_category !== null) {
          this.gotoHome()
        }
      }
      return null
    },
    getAllCounties() {
      return this.$store.dispatch('county/getAllCounties')
    },
    getCategory(data) {
      return this.$store.dispatch('category/getAllCategoryForLocation', data)
    },
    // getAllSubCategories() {
    //   return this.$store.dispatch('subCategory/getAllSubCategories')
    // },
    getBusinessProfile() {
      return this.$store.dispatch('businessProfile/getBusinessProfileListing', this.profile_param)
    },
    getImage(image) {
      return process.env.VUE_APP_STORAGE_PATH + image
    },
    getUserText(firstName, lastName) {
      const firstChar = firstName.charAt(0)
      const lastChar = lastName.charAt(0)
      return firstChar + lastChar
    },
    logout() {
      this.$store.dispatch('businessProfile/emptyListParam')
      this.$store.dispatch('businessProfile/emptyDefaultList')
      this.$store.dispatch('businessProfile/updateMessage', 'Please select state')
      this.$router.push({ name: 'login' }).catch(() => {})
      localStorage.clear()
    },
    switchToDashboard() {
      if (this.user_type === 'admin') {
        this.$router.push({ name: 'managers' }).catch(() => {})
      }

      if (this.user_type === 'manager') {
        this.$router.push({ name: 'statistics' }).catch(() => {})
      }

      if (this.user_type === 'staff') {
        this.$router.push({ name: 'members' }).catch(() => {})
      }

      // if (this.user.user_type === 'member' && this.user.member.is_vendor === true) {
      //   this.$router.push({ name: 'vendor-business-profile' }).catch(() => {})
      // }
      // if (this.user.user_type === 'member') {
      //   this.$router.push({ name: 'contacts' }).catch(() => {})
      // }
    },
    showMenu() {
      this.is_menu_show = !this.is_menu_show
    },
    showStateMenu() {
      this.is_state_show = true
    },
    hideStateMenu() {
      const dom = this
      setTimeout(() => {
        dom.is_state_show = false
      }, 200)
    },
    changeStateSearch(id) {
      const data = this.states.filter(item => item.id === id)[0]
      this.selectStateItem(data)
    },
    changeCountySearch(id) {
      const data = this.counties.filter(item => item.id === id)[0]
      this.selectCountyItem(data)
    },
    changeCategorySearch(id) {
      const data = this.categories.filter(item => item.id === id)[0]
      this.selectCategoryItem(data)
    },
    selectStateItem(item) {
      this.state = item
      this.state = item.name
      this.last_search_data.state_name = item.name
      this.last_search_data.state_id = item.id
      this.county = ''
      this.$store.dispatch('county/getCounties', item.id)
      // this.is_county_show = true
      this.profile_param.states = [item.id]
      this.$store.dispatch('businessProfile/enableSort', false)
      if (item.id === 2) {
        this.profile_param.counties = [12]
        const countyName = 'District of Columbia'
        this.last_search_data.county_name = countyName
        this.last_search_data.county_id = 12
        this.county = countyName
      } else {
        this.profile_param.counties = []
        this.$store.dispatch('category/emptyCategory')
        this.$store.dispatch('businessProfile/EmptyProfileList')
        this.last_search_data.county_name = ''
        this.last_search_data.county_id = null
      }

      // this.last_search_data.category_name = ''
      // this.last_search_data.category_id = null

      if (this.profile_param.states.length > 0 && this.profile_param.counties.length > 0) {
        const data = { state_id: item.id, county_id: this.profile_param.counties[0] }
        this.getCategory(data)
      }
    },
    showCountyMenu() {
      this.is_county_show = true
    },
    hideCountyMenu() {
      const dom = this
      setTimeout(() => {
        dom.is_county_show = false
      }, 200)
    },
    selectCountyItem(item) {
      this.county = item.name
      this.last_search_data.county_name = item.name
      this.last_search_data.county_id = item.id
      // this.center = [item.lat, item.lng]
      this.$store.dispatch('businessProfile/enableSort', false)
      this.hideCountyMenu()
      this.profile_param.counties = [item.id]
      this.$store.dispatch('category/emptyCategory')
      this.$store.dispatch('businessProfile/EmptyProfileList')
      if (this.profile_param.states > 0 && this.profile_param.counties.length > 0) {
        const data = { state_id: this.profile_param.states[0], county_id: item.id }
        this.getCategory(data)
      }
    },
    selectCategoryItem(item) {
      this.category = item.name
      this.last_search_data.category_name = item.name
      this.last_search_data.category_id = item.category_id
      this.$store.dispatch('businessProfile/enableSort', false)
      this.is_category_show = true
      this.profile_param.categories = [item.category_id]
    },
    showCategoryMenu() {
      this.is_category_show = true
    },
    hideCategoryMenu() {
      const dom = this
      setTimeout(() => {
        dom.is_category_show = false
      }, 200)
    },
  },
}
</script>

<style scoped>

</style>
