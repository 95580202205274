<template>
  <b-card>

    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-avatar
            v-if="user.avatar === null || user.avatar === ''"
            variant="success"
            :text="getUserText(user.first_name, user.last_name)"
            size="80"
          />
          <b-avatar
            v-else
            :src="getImage(user.avatar)"
            size="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-primary"
          size="sm"
          class="mb-75 mr-75"
          @click="upload"
        >
          Upload
        </b-button>
        <!--/ upload button -->

        <!-- reset -->
        <!--/ reset -->
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="First Name"
            label-for="account-first_name"
          >
            <b-form-input
              v-model="user.first_name"
              placeholder="First Name"
              name="firstname"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Last Name"
            label-for="account-last_name"
          >
            <b-form-input
              v-model="user.last_name"
              name="last_name"
              placeholder="Last Name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="E-mail"
            label-for="account-e-mail"
          >
            <b-form-input
              v-model="user.email"
              name="email"
              placeholder="Email"
            />

          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Phone"
            label-for="account-phone"
          >
            <b-form-input
              v-model="user.phone"
              name="phone"
              placeholder="Phone"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="user_type !== 'member'"
          sm="6"
        >
          <b-form-group
            label="Job Title"
            label-for="account-job-title"
          >
            <b-form-input
              v-model="user.job_title"
              name="job-title"
              placeholder="Job Title"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="user_type === 'member'"
          sm="6"
        >
          <b-form-group
            label="Profession"
            label-for="account-profession"
          >
            <b-form-input
              v-model="user.profession"
              name="profession"
              placeholder="Profession"
            />
          </b-form-group>
        </b-col>

        <!-- alert -->
        <b-col
          cols="12"
          class="mt-75"
        >
          <h3 class="mt-2">
            Address Information
          </h3>
          <b-row>
            <b-col sm="6">
              <b-form-group
                label="Address"
                label-for="account-address"
              >
                <b-form-input
                  v-model="user.address1"
                  name="address_1"
                  placeholder="Address"
                />
              </b-form-group>
            </b-col>

            <b-col sm="6">
              <b-form-group
                label="Address 2"
                label-for="account-address_2"
              >
                <b-form-input
                  v-model="user.address2"
                  name="address_2"
                  placeholder="Address 2"
                />
              </b-form-group>
            </b-col>

            <b-col sm="3">
              <b-form-group
                label="city"
                label-for="account-city"
              >
                <b-form-input
                  v-model="user.city"
                  name="city"
                  placeholder="City"
                />
              </b-form-group>
            </b-col>

            <b-col sm="3">
              <b-form-group
                label="state"
                label-for="account-state"
              >
                <b-form-input
                  v-model="user.state"
                  name="state"
                  placeholder="State"
                />
              </b-form-group>
            </b-col>
            <b-col sm="3">
              <b-form-group
                label="County"
                label-for="account-county"
              >
                <b-form-input
                  v-model="user.county"
                  name="county"
                  placeholder="County"
                />
              </b-form-group>
            </b-col>

            <b-col sm="2">
              <b-form-group
                label="Zip Code"
                label-for="account-zip_code"
              >
                <b-form-input
                  v-model="user.zip_code"
                  name="zip_code"
                  placeholder="Zip Code"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <!--/ alert -->

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="mt-2 mr-1"
            :disabled="is_profile_edited ? false: true"
            @click="saveUserInfo"
          >
            Save changes
          </b-button>
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Reset
          </b-button> -->
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BAvatar, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file.file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = error => reject(error)
})
export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      user: {
        first_name: '',
        last_name: '',
        email: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        county: '',
        zip_code: '',
        avatar: null,
        phone: '',
        selectedFiles: [],
        job_title: '',
        accept: 'image/jpeg, image/png, image/jpg',
        profession: '',
      },
      user_type: '',
      photo: [],
      multiple: false,
      is_profile_edited: false,
      attachments: [],
    }
  },
  computed: {
    userData() {
      return this.$store.state.user.profile
    },
  },
  watch: {
    user: {
      handler() {
        this.is_profile_edited = true
      },
      deep: true,
    },
    '$store.state.user.profile': {
      handler() {
        this.user = this.userData
      },
      deep: true,
    },
  },
  mounted() {
    setTimeout(() => {
      this.is_profile_edited = false
    }, 300)
    this.loadUser()
    this.user_type = localStorage.getItem('gts_user_type')
  },
  methods: {
    loadUser() {
      this.$store.dispatch('user/setProfile')
    },
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
    upload() {
      const el = document.createElement('input')
      el.type = 'file'
      if (this.multiple) {
        el.multiple = true
      }
      el.accept = this.accept
      el.onchange = event => {
        const { files } = event.target
        if (this.multiple) {
          this.selectedFiles = [...this.selectedFiles, ...files]
          this.onFileUpload(this.selectedFiles)
        } else {
          const reader = new FileReader()
          reader.readAsDataURL(files[0])
          reader.onload = () => {
            this.onFileUpload({
              file: files[0],
              dataUrl: reader.result,
            })
          }
        }
      }
      el.click()
    },
    onFileUpload(file) {
      // this.user.avatar = file.dataUrl
      const fd = new FormData()
      fd.append('avatar', file.file)
      const dom = this

      // eslint-disable-next-line func-names
      return (async function () {
        const data = await toBase64(file)
        const fileItem = { name: file.file.name, file: data }
        dom.$store.dispatch('user/uploadProfilePhoto', fileItem)
      }())
    },
    saveUserInfo() {
      const data = this.user
      data.id = this.userData.id
      if (data.admin) {
        delete data.admin
      }
      this.$store.dispatch('user/updateProfile', data)
    },
    getImage(image) {
      return process.env.VUE_APP_STORAGE_PATH + image
    },
    getUserText(firstName, lastName) {
      const firstChar = firstName.charAt(0)
      const lastChar = lastName.charAt(0)
      return firstChar + lastChar
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
