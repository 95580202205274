<template>
  <section>
    <ServicesTopBar />
    <ProfileSettings />
    <SiteFooter />
  </section>
</template>

<script>
import ServicesTopBar from '../components/services/ServicesTopBar.vue'
import ProfileSettings from '../components/services/ProfileSettings.vue'
import SiteFooter from '../components/site/SiteFooter.vue'

export default {
  components: { ServicesTopBar, ProfileSettings, SiteFooter },
}
</script>

<style scoped>

</style>
