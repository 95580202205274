<template>
  <div class="container pt-5 pb-5">
    <Profile />
  </div>
</template>

<script>
import Profile from '../../auth/Profile.vue'

export default {
  components: {
    Profile,
  },
}
</script>

<style scoped>

</style>
