<template>
  <section class="footer__view__box">
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-3 mb-3 footer-content_view">
          <h1
            class=""
            @click="gotoHome"
          >
            <img
              :src="Logo"
              class="img-fluid"
              alt="logo"
              style="width: 200px !important"
            >
          </h1>
          <!-- <p class="mt-2">
            Sign-up to receive regular updates from us.
          </p>
          <div class="subscribe_box">
            <input placeholder="Enter your email">
            <feather-icon
              size="20"
              icon="ArrowRightIcon"
            />
          </div> -->
        </div>
        <div class="col-12 col-sm-12 mb-3 col-md-2">
          <h4 class="footer_header">
            Company
          </h4>
          <span
            v-for="(item, index) in footers.company"
            :key="index"
            class="footer_item"
            @click="$router.push({ name: `${item.url}` }).catch(() => {})"
          >{{ item.is_visible ? item.name : '' }}</span>
        </div>
        <div class="col-12 col-sm-12 mb-3 col-md-2">
          <h4 class="footer_header">
            Support
          </h4>
          <span
            v-for="(item, index) in footers.support"
            :key="index"
            class="footer_item"
            @click="$router.push({ name: `${item.url}` }).catch(() => {})"
          >{{ item.name }}</span>
        </div>
        <div class="col-12 col-sm-12 mb-3 col-md-3">
          <h4 class="footer_header">
            Company
          </h4>
          <span class="footer_item">Email: {{ contact.email }}</span>
          <span class="footer_item">Phone: {{ contact.phone }}</span>
          <span class="footer_item">Address: <br>{{ contact.address }}
            <br> {{ contact.city }}, {{ contact.state }} {{contact.zip_code }}</span>
          <div class="social_link mt-2">
            <feather-icon
              size="20"
              icon="FacebookIcon"
            />
            <feather-icon
              size="20"
              icon="TwitterIcon"
            />
            <feather-icon
              size="20"
              icon="YoutubeIcon"
            />
            <feather-icon
              size="20"
              icon="InstagramIcon"
            />
          </div>
        </div>
      </div>

      <!-- end of footer view -->
      <div class="sub__footer_view">
        <p>{{ footers.copyright }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import Logo from '../../../assets/images/logo/logo.png'

export default {
  data() {
    return {
      Logo,
      footers: {
        company: [
          // { name: 'About', url: 'about-us', is_visible: true },
          { name: 'FAQ', url: 'faqs', is_visible: true },
          { name: 'Blog', url: 'blogs', is_visible: false },
          { name: 'Contact Us', url: 'contact-us', is_visible: true },
        ],
        support: [
          { name: 'Help Center', url: 'help-center', is_visible: false },
          { name: 'Privacy Policy', url: 'privacy-policy', is_visible: true },
          { name: 'Terms', url: 'terms', is_visible: true },
        ],
        copyright: '2023 © Hyper Marketing Live. All rights reserved.',
      },
      contact: {
        email: 'support@thegotosource.com',
        phone: '703-944-3884',
        address: '1749 Old Meadow Rd Suite 320',
        state: 'VA',
        city: 'Tysons',
        zip_code: '22102',
      },
    }
  },
  computed: {
    contents() {
      return this.$store.state?.page_content?.page_footer_contents
    },
    contacts() {
      return this.$store.state?.page_content?.contact_contents
    },
  },
  watch: {
    '$store.state.page_content.page_footer_contents': {
      handler(oldValue, newValue) {
        if (oldValue.content !== null) {
          this.footers = oldValue.content
        }

        if (newValue && newValue.content !== null) {
          this.footers = newValue.content
        }
      },
      deep: true,
    },
    '$store.state.page_content.contact_contents': {
      handler(oldValue, newValue) {
        if (oldValue.content !== null) {
          this.contact = oldValue.content
        }
        if (newValue && newValue.content !== null) {
          this.contact = newValue.content
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getContent()
  },
  methods: {
    gotoHome() {
      this.$router.push({ name: 'home' }).catch(() => {})
    },
    getContent() {
      this.$store.dispatch('page_content/getAllFooterContent', 'footer')
      this.$store.dispatch('page_content/getAllContactContent', 'contacts')
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
